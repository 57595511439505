<template>
	<div class="broker-office">
		<div class="container">
			<div class="broker-office__wrapper">
				<!--				<Breadcrumb :pages="pages" />-->

				<form class="broker-office__body">
					<h1 class="broker-office__title">
						{{ $t('web.agent_profile_title') }}
					</h1>
					<broker-subscription :broker="{subscription: subscription}" :agency="agency" />
					<div class="broker-office__input">
						<add-photo
							:label="$t('web.add_photo')"
							:default-image="photo"
							@upload="updatePhoto"
						/>

						<div class="broker-office__input-item">
							<div>
								<label> {{ $t('web.name') }}* </label>
								<or-input
									v-model="name"
									class="main-type"
									:placeholder="$t('web.name')"
									:error="{status: !!errs.name, text: errs.name ? t(errs.name) : ''}"
								/>
							</div>
						</div>
						<div class="broker-office__input-item">
							<div>
								<label>{{ $t('web.description') }}</label>
								<or-textarea
									v-model="description"
									class="main-type broker-office__textarea"
									:placeholder="$t('web.description')"
									:error="{status: !!errs.description, text: errs.description ? t(errs.description) : ''}"
									counter
								/>
							</div>
						</div>

						<div class="broker-office__input-item">
							<div>
								<label>{{ $t('web.languages') }}</label>
								<or-drop-down>
									<template #toggle>
										<or-input
											class="main-type"
											:placeholder="$t('web.languages')"
											icon-after="arrow"
											v-model="selectedLanguages"
											:error="{status: !!errs.languages, text: errs.languages ? t(errs.languages) : ''}"
											truncate
											readonly
										/>
									</template>
									<template #content>
										<broker-languages
											:languages="availableLanguages"
											:select-langs="languages"
											@update="selectLanguages"
										/>
									</template>
								</or-drop-down>
								<p>
									{{ $t('web.languages_select_text') }}
								</p>
							</div>
							<div>
								<label>{{ $t('web.phone') }}*</label>
								<TelInput
									class="tel-input tel-input__error"
									v-model.trim="phone"
                  @focus="() => touchField('phone')"
                  @click="() => touchField('phone')"
								/>
								<div class="tel-input-error" v-if="errs.phone && touched.phone">
									{{ $t(errs.phone) }}
								</div>
								<!--								<or-input-->
								<!--									class="main-type"-->
								<!--									v-model.trim="broker.phone"-->
								<!--									:placeholder="$t('web.phone')"-->
								<!--									@input="clearError('phone')"-->
								<!--									:error="errors.phone"-->
								<!--								/>-->
							</div>
						</div>

						<div class="broker-office__input-item">
							<div>
								<label>{{ $t('web.email') }}</label>
								<or-input
									type="email"
									class="main-type"
									v-model="email"
                  disabled
									:placeholder="$t('web.email')"
									:error="{status: !!errs.email, text: errs.email ? t(errs.email) : ''}"
								/>
							</div>
							<div>
								<label>WhatsApp</label>
								<or-input
									class="main-type"
									v-model="whatsapp"
									:error="{status: !!errs.whatsapp, text: errs.whatsapp ? t(errs.whatsapp) : ''}"
								/>
							</div>
						</div>

						<div class="broker-office__input-item">
							<div>
								<label>Telegram</label>
								<or-input
									class="main-type"
									placeholder="@"
									v-model="telegram"
                  :pasteHandler="pasteTelegram"
									:error="{status: !!errs.telegram, text: errs.telegram ? t(errs.telegram) : ''}"
								/>
							</div>

							<div>
								<label>Instagram</label>
								<or-input
									class="main-type"
									placeholder="@"
									v-model="instagram"
                  :pasteHandler="pasteInstagram"
									:error="{status: !!errs.instagram, text: errs.instagram ? t(errs.instagram) : ''}"
								/>
							</div>
						</div>
						<div class="broker-office__input-item">
							<div>
								<label>YoutTube</label>
								<or-input
									class="main-type"
									v-model="youtube"
									:error="{status: !!errs.youtube, text: errs.youtube ? t(errs.youtube) : ''}"
									:placeholder="$t('web.link')"
								/>
							</div>
						</div>
						<div v-if="agency" class="broker-office__input-item">
							<div>
								<label>{{ $t('web.position_in_agency') }}</label>
								<or-input
									class="main-type"
									v-model="position"
									:placeholder="$t('web.agent_position')"
								/>
							</div>
						</div>

						<div class="broker-office__input-item">
							<div v-for="link in links" :key="link.sort">
								<label>{{ $t('web.add_link') }}</label>
								<or-input
									class="main-type"
									v-model.trim="link.name"
									:placeholder="`${$t(
										'web.example'
									)} Youtube, Twitter, website etc.`"
								/>
								<or-input
									class="main-type"
									v-model.trim="link.url"
									:placeholder="$t('web.link')"
								/>
							</div>
						</div>
					</div>
					<div v-if="agency" class="broker-office__agency">
						<div class="broker-office__agency-left">
							<span class="broker-office__agency-title">
								{{ $t('web.agency_logo') }}
							</span>
							<div class="broker-office__agency-img-container">
								<img
									v-if="agency.logo"
									class="broker-office__agency-img"
									:src="agency.logo"
									:alt="agency.name"
									width="100"
									height="100"
									decoding="async"
								/>
								<img
									v-else
									class="broker-office__agency-img broker-office__agency-img--skeleton"
									src="@/assets/img/svg/broker/skeleton-img.svg"
									:alt="agency.name"
									width="44"
									height="44"
									decoding="async"
								/>
							</div>
						</div>

						<div class="broker-office__agency-right">
							<div class="broker-office__agency-right-item">
								<span class="broker-office__agency-title">
									{{ $t('web.agency_name') }}
								</span>
								<p>{{ agency.name }}</p>
							</div>
							<div
								v-if="position"
								class="broker-office__agency-right-item"
							>
								<span class="broker-office__agency-title">
									{{ $t('web.agency_position') }}
								</span>
								<p>{{ position }}</p>
							</div>
						</div>
					</div>
					<div class="broker-office__btns">
						<!--						<or-link-->
						<!--							v-if="!slug"-->
						<!--							:to="{-->
						<!--								name: 'ChangePassword',-->
						<!--								params: { lang: $store.state.uiLanguage }-->
						<!--							}"-->
						<!--							:height="48"-->
						<!--						>-->
						<!--							{{ $t('web.change_password') }}-->
						<!--						</or-link>-->
						<or-button
							@click="submit"
							:height="48"
							:kind="!slug ? 'secondary' : 'main'"
						>
							{{ $t('web.save') }}
						</or-button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { createRequestBroker } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestBrokerConfigs'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import Breadcrumb from '@/components/Breadcrumb.vue'
import AddPhoto from '@/modules/broker/components/AddPhoto.vue'
import BrokerLanguages from '@/modules/broker/components/BrokerLanguages.vue'
import TelInput from '@/components/TelInput.vue'
import BrokerSubscription from '@/modules/broker/components/BrokerSubscription.vue'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { z } from 'zod'

const props = defineProps({
  pages: {
    type: Object,
    required: true,
    default: () => ({})
  },
  slug: {
    type: String,
    default: null
  }
})

const store = useStore()
const { commit } = store
const { t } = useI18n()

const brokerScheme = toTypedSchema(
	z.object({
    name: z
      .string({ required_error: ('web.validation_presence_of_name') })
      .min(1, ('web.validation_presence_of_name'))
      .default(''),
		email: z
			.string({ required_error: ('web.validation_presence_of_email') })
			.min(1, ('web.validation_presence_of_email'))
			.email(('web.validation_format_of_email'))
      .default(''),
		phone: z
			.string({ required_error: ('web.validation_presence_of_phone') })
			.regex(/^\+(?:[0-9] ?){6,14}[0-9]$/, ('web.validation_format_of_phone')),
		whatsapp: z.string().regex(/^\+[0-9]*$/, ('web.validation_format_of_whatsapp')).default('+'),
		description: z.string().max(200, ('web.validation_max_length_of_description')).default(''),
		youtube: z.string().url(('web.validation_format_of_youtube')).optional(),
    telegram: z.string().regex(/^@[a-zA-Z0-9._]*$/, ('web.validation_format_of_telegram')).default('@'),
    instagram: z.string().regex(/^@[a-zA-Z0-9._]*$/, ('web.validation_format_of_instagram')).default('@'),
    languages: z.array(z.string()).default([]),
    links: z.array(z.any()).default([]),
    subscription: z.any().optional(),
    position: z.string().optional()
	})
)

const { validate, setFieldValue, setFieldError, meta, values: form, defineField, errors: errs, handleSubmit } = useForm({
	validationSchema: brokerScheme
})

const touched = reactive({
  phone: false
})

const [email] = defineField('email')
const [phone, phoneProps] = defineField('phone')
const [name] = defineField('name')
const [youtube] = defineField('youtube')
const [description] = defineField('description')
const [links] = defineField('links')
const [languages] = defineField('languages')
const [subscription] = defineField('subscription')
const [position] = defineField('position')
const [_whatsapp] = defineField('whatsapp')
const whatsapp = computed({
  get() {
    return _whatsapp.value
  },
  set(value) {
    let result = value.replace(/\s/g, '')
    const regex = /^\+/
    if (!result.match(regex)) {
      result = '+' + result
    }
    _whatsapp.value = result
  }
})

const [_instagram] = defineField('instagram')
const instagram = computed({
  get() {
    return _instagram.value
  },
  set(value) {
    let result = value
    const regex = /^@/
    if (!result.match(regex)) {
      result = '@' + result
    }
    _instagram.value = result
  }
})

const [_telegram] = defineField('telegram')
const telegram = computed({
  get() {
    return _telegram.value
  },
  set(value) {
    let result = value.replace(/\s+/, '')
    const regex = /^@/
    if (!result.match(regex)) {
      result = '@' + result
    }
    _telegram.value = result
  }
})

const availableLanguages = ref([])

const photo = ref()
const agency = ref()
const selectedLanguages = computed(() => {
  return languages.value
    .map((langCode) => t('language.' + langCode))
    .sort()
    .join(', ')
})

await _load()

function pasteInstagram(pasteValue, currentValue) {
  const regexTme = /(https?:\/\/)?www.instagram.com\/([a-zA-Z0-9_.]+)(\?([wW])+)?/
  const regexStr = /(@)?([a-zA-Z0-9_-]+)/
  const match = pasteValue.match(regexTme)
  let result
  if (pasteValue.includes(' ')) {

  } else if (match && match[2]) {
    result = match[2]
  } else if (pasteValue.match(regexStr)) {
    result = pasteValue
  }
  if (!result) {
    commit('showNotif', {
      type: 'error',
      text: t('web.error_clipboard_wrong_instagram_format')
    })
  }
  return result ?? currentValue
}

function pasteTelegram(pasteValue, currentValue) {
  const regexTme = /(https?:\/\/)?t.me\/([a-zA-Z0-9_.]+)(\?([wW])+)?/
  const regexStr = /(@)?([a-zA-Z0-9_-]+)/
  const match = pasteValue.match(regexTme)
  let result
  if (pasteValue.includes(' ')) {

  } else if (match && match[2]) {
    result = match[2]
  } else if (pasteValue.match(regexStr)) {
    result = pasteValue
  }
  if (!result) {
    commit('showNotif', {
      type: 'error',
      text: t('web.error_clipboard_wrong_telegram_format')
    })
  }
  return result ?? currentValue
}

function _processingResponse(response) {
	if (response.status) {
		const {
			photo: newPhoto,
			email: newEmail,
			links: newLinks,
			agency: newAgency
		} = response.response
		photo.value = newPhoto
		email.value = newEmail
		agency.value = newAgency
		// Ужас. Кошмар. Короче находим ссылку с подходящим уровнем сортироки и вставляем, если её нет, то выводим по умолчанию
		if (links?.value.length) {
			links.value = links.value.map((itm) =>
				newLinks.find((link) => newLinks.sort === itm.sort)
					? { ...newLinks.find((link) => newLinks.sort === itm.sort) }
					: itm
			)
		}
		Object.keys(form).forEach((key) => {
			if (response.response[key]) {
        let value
        switch (key) {
          case 'languages':
            value = response.response[key].split(',')
            break
          case 'phone':
            value = '+' + response.response[key]
            break
          case 'telegram':
            telegram.value = response.response[key]
            break
          case 'instagram':
            instagram.value = response.response[key]
            break
          case 'whatsapp':
            whatsapp.value = response.response[key]
            break
          case 'links':
            break
          default:
            value = response.response[key]
        }
        if (value) setFieldValue(key, value)
			}
		})
    clearTouchFlags()
	}
}

function selectLanguages(langs) {
	languages.value = [...langs]
}

const submit = handleSubmit(values => {
  const payload = {
    links: []
  }
  Object.entries(values).forEach(([key, value]) => {
    if (key === 'links') return
    let v = value
    if (key === 'languages') {
      if (value.length) {
        if (typeof value === 'string') {
          v = [value]
        } else {
          v = value
        }
      }
    }

    if ((key === 'instagram' || key === 'telegram' || key === 'phone' || key === 'whatsapp') && value) {
      v = value.substring(1)
    }

    if (v && v.length > 0) payload[key] = v
  })
  links.value.forEach((itm) => {
    if (itm.name && itm.url) {
      payload.links.push(itm)
    }
  })
  if (props.slug) {
    payload.slug = props.slug
  }
  createRequestBroker(requestConfigs.POSTSetProfileBroker, {
    jsonPayload: {
      ...payload
    }
  })
    .then(async (response) => {
      if (response.status) {
        if (!props.slug) {
          await createRequestBroker(requestConfigs.GETProfileBroker)
            .then((profile) => {
              const { commit } = store
              if (!profile.status) return
              commit('updateProfile', profile.response)
              _processingResponse(profile)
            })
            .catch(() => {})
        }

        store.commit('showNotif', {
          type: 'success',
          text: t('web.notification_profile_update_success')
        })
      }
    })
    .catch((err) => {
      if (Object.keys(err.errors).length) {
        Object.entries(err.errors).forEach(([key, value]) => {
          setFieldError(key, value)
        })
      }
    })
})

function updatePhoto(photo) {
	const { commit } = store
	createRequestBroker(requestConfigs.POSTSetProfileBrokerPhoto, {
		jsonPayload: {
			photo
		}
	}).then((res) => {
		if (res.status) {
			commit('showNotif', {
				type: 'success',
				text: t('web.notification_image_upload_success')
			})
		}
	})
}

function clearTouchFlags() {
  for (const [key, value] of Object.entries(touched)) {
    touched[key] = false
  }
}

function touchField(fieldName) {
  touched[fieldName] = true
}

async function _load() {
	createRequestBroker(requestConfigs.GETProfileBroker).then((profile) => {
		if (profile.status) {
			commit('updateProfile', profile.response)
		}
	})
	for (let index = 1; index < 3; index++) {
		links.value.push({
			name: null,
			url: null,
			sort: index
		})
	}
	createRequestBroker(requestConfigs.GETProfileAllowedLanguages).then(
		(response) => {
			availableLanguages.value = [...response]
		}
	)
	if (props.slug) {
		createRequestBroker(requestConfigs.GETProfileBrokerBySlug, {
			routerPayload: {
				slug: props.slug
			}
		}).then(_processingResponse)
	} else {
		const { profile } = store.state.broker
		if (Object.keys(profile).length) {
			_processingResponse({
				status: true,
				response: profile
			})
		} else {
			createRequestBroker(requestConfigs.GETProfileBroker).then(
				_processingResponse
			)
		}
	}
}
</script>
<style lang="scss" scoped>
.tel-input {
	width: 100%;
	max-width: none;
}
.tel-input-error {
	color: #db3939;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	font-family: OpenSans;
	box-sizing: border-box;
}
</style>

<style lang="scss">
.broker-office {
	margin-bottom: 80px;
	&__title {
		font-size: 28px;
		font-weight: 700;
		line-height: 120%;
		margin: 0;
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}
	&__body {
		display: flex;
		flex-direction: column;
		gap: 40px;
		max-width: 720px;
		width: 100%;
	}
	&__input {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}
	&__input-item {
		display: flex;
		gap: 40px;
		& > div {
			display: flex;
			flex-direction: column;
			gap: 8px;
			width: 100%;
			& > label:first-child {
				color: #808080;
				font-size: 12px;
				font-weight: 700;
				line-height: normal;
				margin: 0;
			}
			& > p {
				margin: 0;
				font-size: 12px;
			}
		}
	}
	&__textarea {
		color: red;
		& textarea {
			min-height: 90px;
			padding: 15px !important;
		}
	}
	&__btns {
		display: flex;
		gap: 40px;
		margin-top: 20px;
		& > button,
		& > a {
			width: 100%;
			max-width: 340px;
		}
	}
	&__agency {
		display: flex;
		gap: 40px;
		&-left {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}
		&-title {
			color: var(--secondary-1, #808080);
			font-size: 14px;
			font-weight: 600;
			line-height: normal;
		}
		&-img-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100px;
			height: 100px;
			border-radius: 4px;
			border: 1px solid var(--opacity-black-10, rgba(0, 0, 0, 0.1));
			overflow: hidden;
		}
		&-img {
			object-fit: contain;

			&--skeleton {
				width: 44px;
				height: 44px;
			}
		}
		&-right {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
		&-right-item {
			display: flex;
			flex-direction: column;
			gap: 4px;
			p {
				color: var(--text-1, #000);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 21px;
				margin: 0;
			}
		}
	}
	@media screen and (max-width: 913px) {
		&__input-item {
			gap: 20px;
		}
		&__btns {
			gap: 20px;
		}
	}
	@media screen and (max-width: 479px) {
		&__body {
			gap: 25px;
		}
		&__title {
			font-size: 22px;
		}
		&__input {
			gap: 20px;
		}
		&__input-item {
			gap: 20px;
			flex-direction: column;
		}
		&__btns {
			flex-direction: column;
		}
		&__agency {
			flex-direction: column;
			gap: 20px;
			&-right {
				order: -1;
			}
		}
	}
}
</style>
