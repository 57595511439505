<template>
	<div class="broker-languages">
		<div class="broker-languages__item" v-for="lang in languages" :key="lang">
			<!--	Флаг страны	-->
			<img :src="`/images/countries/${lang}.svg`" alt="" decoding="async" />
			<or-checkbox
				:label="$t('language.' + lang)"
				:value="lang"
				@input="updateValues"
				:checked="langs.includes(lang)"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		languages: {
			type: Array,
			required: true,
			default: () => []
		},
		selectLangs: {
			type: [Array, String],
			default: () => []
		}
	},
	name: 'BrokerLanguages',
	data() {
		return {
			langs: []
		}
	},
	mounted() {
		if (typeof this.selectLangs === 'string') {
			this.langs.push(...this.selectLangs.split(','))
		} else {
			this.langs = [...this.selectLangs]
		}
	},
	methods: {
		updateValues(evt) {
			const currentValue = evt.target.value
			const currentValIndex = this.langs.indexOf(currentValue)
			if (currentValIndex > -1) {
				this.langs.splice(currentValIndex, 1)
			} else {
				this.langs.push(currentValue)
			}

			this.$emit('update', this.langs)
		}
	}
}
</script>

<style scoped lang="scss">
.broker-languages {
	max-width: 100%;
	padding: 10px;
	background: #ffffff;
	box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	max-height: 300px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: auto;
	&__item {
		display: flex;
		align-items: center;
		gap: 10px;

    img {
      height: 18px;
      width: 27px;
    }
	}
}
</style>
